"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'Liyuu Digital Collection',
    organizationId: 'no2yX8ebUOAUFCtALyNA',
    version: '1.1',
    ionicAppId: '745bd2fc',
    applicationKey: 'liyuu',
    applicationType: 'standalone',
    artistId: 'WtykYOWcQXgrmMokHJPtZy6Z3Hf1',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.liyuu',
        appId: '6480279493',
    },
    android: {
        bundleId: 'com.utoniq.liyuu',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'threads', 'gachas', 'myProfile'],
    },
    deeplinkUrl: 'https://liyuu.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3564140',
    storagePath: 'liyuu',
    dynamicLinkPrefix: 'liyuu',
    deeplinkProd: 'liyuu.utoniq.com',
};
exports.default = appConfig;
